var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Home" }, [
    _c(
      "div",
      [
        _c("Banner"),
        _c("Sailingintroduction"),
        _c("Exhibitionintroduction", { attrs: { id: "duction" } }),
        _c("Exhibitionactivities", { attrs: { id: "activites" } }),
        _c("Pastreview", { attrs: { id: "Pastreview" } }),
        _c("Review", { attrs: { id: "Review" } }),
        _c("Media", { attrs: { id: "Media" } }),
        _c("MediaCooperative", { attrs: { id: "CooperativeMedia" } }),
        _c("Footer")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }