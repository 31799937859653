var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Sailing_warp" }, [
    _c("div", { staticClass: "Backgroundmaps", style: _vm.bg }, [
      _c("div", { staticClass: "bg_text" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("SetSail.title")))]),
        _c("div", { staticClass: "text_warp" }, [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("SetSail.SailingContents.Contents0")))
          ]),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("SetSail.SailingContents.Contents1")))
          ]),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("SetSail.SailingContents.Contents2")))
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$t("SetSail.SailingContents.Contents3")))])
        ])
      ]),
      _c("div", { staticClass: "Icons" }, [
        _c("ul", { staticClass: "list_icon" }, [
          _c("li", [
            _c("img", {
              staticClass: "iconi",
              attrs: { src: _vm.icon[0], alt: "" }
            })
          ]),
          _c("li", [
            _c("img", {
              staticClass: "iconi",
              attrs: { src: _vm.icon[1], alt: "" }
            })
          ]),
          _c("li", [
            _c("img", {
              staticClass: "iconi",
              attrs: { src: _vm.icon[2], alt: "" }
            })
          ]),
          _c("li", [
            _c("img", {
              staticClass: "iconi",
              attrs: { src: _vm.icon[3], alt: "" }
            })
          ]),
          _c("li", [
            _c("img", {
              staticClass: "iconi",
              attrs: { src: _vm.icon[4], alt: "" }
            })
          ]),
          _c("li", [
            _c("img", {
              staticClass: "iconi",
              attrs: { src: _vm.icon[5], alt: "" }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "center" }, [
        _c("div", { staticClass: "introduce_text" }, [
          _c("ul", { staticClass: "left_text" }, [
            _c("li", { staticClass: "list" }, [
              _c("div", {
                staticClass: "box_img box-chanzhan",
                style: {
                  backgroundImage: "url('" + _vm.pickerMain[0] + "')"
                }
              }),
              _c("div", { staticClass: "text_con" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("SetSail.textCon.Exhibitors")))
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("SetSail.textCon.ExhibitionContent")))
                ]),
                _c(
                  "a",
                  {
                    staticClass: "Learnmore",
                    attrs: { href: "/" + _vm.$i18n.locale + "/article/1/why" }
                  },
                  [_vm._v(_vm._s(_vm.$t("SetSail.textCon.LearnMore")))]
                )
              ])
            ]),
            _c("li", { staticClass: "list" }, [
              _c("div", {
                staticClass: "box_img box-guanzhan",
                style: {
                  backgroundImage: "url('" + _vm.pickerMain[1] + "')"
                }
              }),
              _c("div", { staticClass: "text_con" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("SetSail.textCon.Exhibition")))
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("SetSail.textCon.ExhibitionContents")))
                ]),
                _c(
                  "a",
                  {
                    staticClass: "Learnmore",
                    attrs: { href: "/" + _vm.$i18n.locale + "/article/2/why" }
                  },
                  [_vm._v(_vm._s(_vm.$t("SetSail.textCon.LearnMore")))]
                )
              ])
            ])
          ]),
          _c("a", { attrs: { href: "javaScript:;" } }, [
            _c("img", { attrs: { src: _vm.pickerMain[2], alt: "" } })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }