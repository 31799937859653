var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Media", style: _vm.bgimgUrl }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("MediaInformation.Title")))])
      ]),
      _c("div", { staticClass: "Choice" }, [
        _c("ul", { staticClass: "list_warp" }, [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "texts",
                class: { action: _vm.Title == 0 },
                attrs: { href: "javaScript:;" },
                on: { click: _vm.dynamics }
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("MediaInformation.listWarp0")) +
                    "\n                    "
                )
              ]
            )
          ]),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "texts",
                class: { action: _vm.Title == 1 },
                attrs: { href: "javaScript:;" },
                on: { click: _vm.report }
              },
              [_vm._v(_vm._s(_vm.$t("MediaInformation.listWarp1")))]
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "media_warp" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.Title == 0,
              expression: "Title==0"
            }
          ],
          staticClass: "boxOne"
        },
        [
          _c("div", { staticClass: "media_main" }, [
            _c(
              "div",
              { staticClass: "left_Warp" },
              _vm._l(_vm.currentPageData, function(item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index == _vm.selectList,
                        expression: "index == selectList "
                      }
                    ],
                    key: item.id,
                    staticClass: "left"
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: item.imgUrl,
                          expression: "item.imgUrl"
                        }
                      ],
                      attrs: { alt: "" }
                    }),
                    _c("div", { staticClass: "TitleBars" }, [
                      _c("h3", [_vm._v(_vm._s(item.title))])
                    ])
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "right_box" }, [
              _c(
                "div",
                { staticClass: "Previouspage", on: { click: _vm.prevPage } },
                [_vm._m(0)]
              ),
              _c(
                "div",
                { staticClass: "right_Warp" },
                _vm._l(_vm.currentPageData, function(item, index) {
                  return _c(
                    "a",
                    {
                      key: item.id,
                      staticClass: "right",
                      class: { show_left: _vm.selectList == index },
                      attrs: {
                        href:
                          "/" +
                          _vm.$i18n.locale +
                          "/article/" +
                          item.id +
                          "/news"
                      },
                      on: {
                        mouseover: function($event) {
                          return _vm.SwitchList(index)
                        },
                        click: function($event) {
                          return _vm.gotoDetails(item.id, "news")
                        }
                      }
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(item.title))]),
                      _c("p", [_vm._v(_vm._s(item.summary))])
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "nextpage", on: { click: _vm.nextPage } },
                [_vm._m(1)]
              )
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.Title == 1,
              expression: "Title==1"
            }
          ],
          staticClass: "boxOne"
        },
        [
          _c(
            "div",
            { staticClass: "media_main" },
            [
              _c(
                "router-link",
                {
                  staticClass: "report",
                  attrs: {
                    to: "/" + this.$route.params.lang + "/SeekingReport"
                  }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("MediaInformation.Report")) +
                      "\n                "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "left_Warp" },
                _vm._l(_vm.currentPageDatass, function(item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: index == _vm.selectList,
                          expression: "index == selectList "
                        }
                      ],
                      key: item.id,
                      staticClass: "left"
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: item.imgUrl,
                            expression: "item.imgUrl"
                          }
                        ],
                        attrs: { alt: "" }
                      }),
                      _c("div", { staticClass: "TitleBars" }, [
                        _c("h3", [_vm._v(_vm._s(item.title))])
                      ])
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "right_box" }, [
                _c(
                  "div",
                  { staticClass: "Previouspage", on: { click: _vm.prevPage } },
                  [_vm._m(2)]
                ),
                _c(
                  "div",
                  { staticClass: "right_Warp" },
                  _vm._l(_vm.currentPageDatass, function(item, index) {
                    return _c(
                      "a",
                      {
                        key: item.id,
                        staticClass: "right",
                        class: { show_left: _vm.selectList == index },
                        attrs: {
                          href:
                            "/" +
                            _vm.$i18n.locale +
                            "/article/" +
                            item.id +
                            "/expo"
                        },
                        on: {
                          mouseover: function($event) {
                            return _vm.SwitchList(index)
                          },
                          click: function($event) {
                            return _vm.gotoDetails(item.id, "expo")
                          }
                        }
                      },
                      [
                        _c("h3", [_vm._v(_vm._s(item.title))]),
                        _c("p", [_vm._v(_vm._s(item.summary))])
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "nextpage", on: { click: _vm.nextPage } },
                  [_vm._m(3)]
                )
              ])
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: "/images/Exhibitorsservice/left.png", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: "/images/Exhibitorsservice/right.png", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: "/images/Exhibitorsservice/left.png", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: "/images/Exhibitorsservice/right.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }