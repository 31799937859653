<template>
    <div class="Media" :style="bgimgUrl">
        <div class="header">
            <div class="title">
                <h3>{{$t('MediaInformation.Title')}}</h3>
            </div>
            <div class="Choice">
                <ul class="list_warp">
                    <li>
                        <a href="javaScript:;" class="texts" @click="dynamics" :class="{action:Title==0}">
                            {{$t('MediaInformation.listWarp0')}}
                        </a>
                    </li>
                    <li>
                        <a href="javaScript:;" class="texts" @click="report" :class="{action:Title==1}">{{$t('MediaInformation.listWarp1')}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="media_warp">
            <div class="boxOne" v-show="Title==0">
                <div class="media_main">
                    <div class="left_Warp">
                        <div
                                class="left"
                                v-for="(item,index) in currentPageData"
                                :key="item.id"
                                v-show="index == selectList "
                        >
                            <img v-lazy="item.imgUrl" alt/>
                            <div class="TitleBars">
                                <h3>{{item.title}}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="right_box">
                        <div class="Previouspage" @click="prevPage">
                            <div>
                                <img src="/images/Exhibitorsservice/left.png" alt/>
                            </div>
                        </div>
                        <div class="right_Warp">
                            <a :href="`/${$i18n.locale}/article/${item.id}/news`"
                                    class="right"
                                    v-for="(item,index) in currentPageData"
                                    :key="item.id"
                                    :class="{show_left:selectList==index}"
                                    @mouseover="SwitchList(index)"
                                    @click="gotoDetails(item.id, 'news')"
                            >
                                <h3>{{item.title}}</h3>
                                <p>{{item.summary}}</p>
                            </a>
                        </div>
                        <div class="nextpage" @click="nextPage">
                            <div>
                                <img src="/images/Exhibitorsservice/right.png" alt/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="boxOne" v-show="Title==1">
                <div class="media_main">
                    <router-link class="report" :to="`/${this.$route.params.lang}/SeekingReport`">
                        {{$t('MediaInformation.Report')}}
                    </router-link>
                    <div class="left_Warp">
                        <div
                                class="left"
                                v-for="(item,index) in currentPageDatass"
                                :key="item.id"
                                v-show="index == selectList "
                        >
                            <img v-lazy="item.imgUrl" alt/>
                            <div class="TitleBars">
                                <h3>{{item.title}}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="right_box">
                        <div class="Previouspage" @click="prevPage">
                            <div>
                                <img src="/images/Exhibitorsservice/left.png" alt/>
                            </div>
                        </div>
                        <div class="right_Warp">
                            <a :href="`/${$i18n.locale}/article/${item.id}/expo`"
                                    class="right"
                                    v-for="(item,index) in currentPageDatass"
                                    :key="item.id"
                                    :class="{show_left:selectList==index}"
                                    @mouseover="SwitchList(index)"
                                    @click="gotoDetails(item.id, 'expo')"
                            >
                                <h3>{{item.title}}</h3>
                                <p>{{item.summary}}</p>
                            </a>
                        </div>
                        <div class="nextpage" @click="nextPage">
                            <div>
                                <img src="/images/Exhibitorsservice/right.png" alt/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getArticles} from "@/publicApi/article";

    export default {
        data() {
            return {
                bgimgUrl: {
                    backgroundImage: "url(/images/lodin.png)",
                    backgroundRepeat: "no-repeat"
                },
                title: [{id: 1, text: this.$t('MediaInformation.listWarp0')}, {id: 2, text: this.$t('MediaInformation.listWarp1')}],
                total: 4,
                currentPage: 1, //当前页数 ，默认为1
                pageSize: 4, // 每页显示数量
                currentPageData: [], //当前页显示内容
                currentPageDatass: [],//第二块显示内容
                Title: 0, //title切换
                selectList: 0 //列表切换
            };
        },
        mounted() {
            this.getCurrentPageDataExpoNews();
            this.getCurrentPageDataExhibitorNews()
            // console.log(this.pageSize)
        },
        computed: {
            totalPage() {
                return ~~((this.total + this.pageSize - 1) / this.pageSize) || 1
            }
        },
        methods: {
            dynamics() {
                this.Title = 0;
            },
            report() {
                this.Title = 1;
            },
            SwitchList(index) {
                this.selectList = index;
            },
            async getCurrentPageDataExpoNews() {
                let {
                    data: {data: list, total},
                    status,
                    msg
                } = await getArticles({
                    category: "ExpoNews",
                    lang: this.$transformAPILang(),
                    pageIndex: this.currentPage - 1,   //当前页数 
                    pageSize: this.pageSize  //每页显示的数量
                });
                let newList = list.map(item => ({
                    id: item.id,
                    title: item.contents[0].title,
                    imgUrl: (item.contents[0].thumb||'').replace(/!thumb$/, '!thumb_article'),
                    summary: item.contents[0].summary
                }));
                // console.log(pageIndex)
                if (newList.length < 4) {
                    //   console.log(this.currentPageData);

                    newList = [...this.currentPageData.slice(list.length), ...newList]
                }
                this.currentPageData = newList;
                this.total = total;

            },
            async getCurrentPageDataExhibitorNews() {
                let {
                    data: {data: list, total},
                    status,
                    msg
                } = await getArticles({
                    category: "ExhibitorNews",
                    lang: this.$transformAPILang(),
                    pageIndex: this.currentPage - 1,
                    pageSize: this.pageSize
                })
                let newList = list.map(item => ({
                    id: item.id,
                    title: item.contents[0].title,
                    imgUrl: item.contents[0].thumb,
                    summary: item.contents[0].summary
                }));
                if (newList.length < 4) {
                    newList = [...this.currentPageDatass.slice(list.length), ...newList]
                }
                this.currentPageDatass = newList;
                this.total = total;
            },
            //上一页
            async prevPage() {
                // console.log(this.currentPage);
                if (this.currentPage == 1) {
                    return false;
                } else {
                    this.currentPage--;
                    await this.getCurrentPageDataExpoNews();
                    await this.getCurrentPageDataExhibitorNews()
                }
            },
            // 下一页
            async nextPage() {
                if (this.currentPage === this.totalPage) {
                    return false;
                } else {
                    this.currentPage++;
                    await this.getCurrentPageDataExpoNews();
                    await this.getCurrentPageDataExhibitorNews()
                }
            },
            gotoDetails(id, type) {
                // console.log(id)
                this.$router.push({path: `/${this.$i18n.locale}/article/${id}/${type}`})
            }
        }
    };
</script>

<style lang="scss" scoped>
    $width: px2rem((374 + 38) * 2);
    $width-index: 496px;
    .Media {
        width: 100%;
        min-height: 680px;
        background-position-y: 449px;
    }

    .header {
        margin: auto;
        padding-top: px2rem(60);
        width: calc(#{$width} + #{$width-index});
        overflow: hidden;
    }

    .title {
        @include titles();

        line-height: 32px;
        padding-bottom: 3px;
        float: left;

        h3 {
            font-size: 26px;
            font-weight: 600;
            color: #333333;
        }
    }

    .Choice {
        margin-left: 157px;
        float: right;
        margin-top: 40px;
    }

    .list_warp {
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;

        li {
            min-width: 88px;
            height: 32px;
            line-height: 32px;
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            margin-right: 88px;
            font-size: 22px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            padding-bottom: 5px;

            .texts {
                display: block;
                color: rgba(51, 51, 51, 1);
                padding-bottom: 5px;
                margin-bottom: -5px;
            }
        }

        .action {
            border-bottom: 3px solid rgba(250, 210, 20, 1);
        }
    }

    .media_warp {
        width: calc(#{$width} + #{$width-index});
        margin: px2rem(40) auto 0;
        position: relative;
        padding-top: 20px;

        .boxOne {
            position: relative;
        }

        .media_main {
            width: 100%;
            padding-bottom: 50px;
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            justify-content: space-between;
            position: relative;
        }

        .left_Warp {
            width: 100%;
            height: 438px;
            overflow: hidden;
            position: relative;

            .left {
                width: 100%;
                height: 100%;
                transition: all 0.5s;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .TitleBars {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 5555;
                    width: 100%;
                    background: black;
                    opacity: 0.5;
                    height: 40px;
                    line-height: 40px;

                    h3 {
                        font-weight: 500;
                        margin-bottom: 5px;
                        color: rgba(255, 255, 255, 1);
                        font-size: 18px;
                        padding-left: 10px;
                    }
                }
            }
        }

        .right_box {
            position: relative;
            text-align: center;

            .right_Warp {
                width: 100%;
                height: 438px;
                text-align: left;
                overflow: hidden;
                margin-left: 12px;
                display: flex;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                justify-content: space-between;
                flex-direction: column;

                .right {
                    height: 23%;
                    background: #f6f6f6;
                    padding: 10px 23px 11px 22px;
                    border-left: 6px solid #cccccc;
                    box-sizing: border-box;
                    // margin-bottom: 10px;
                    cursor: pointer;
                    width: 100%;

                    h3 {
                        font-weight: 600;
                        margin-bottom: 5px;
                        color: rgba(51, 51, 51, 1);
                        font-size: 18px;
                    }

                    p {
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        line-height: 21px;
                        font-size: 15px;
                        width: 582px;

                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    &.show_left {
                        border-left: 6px solid #fad214;
                    }
                }
            }
        }
    }

    .report {
        position: absolute;
        right: 0;
        top: -56px;
        width: 168px;
        height: 40px;
        background: #fad214;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        border-radius: 6px;
        cursor: pointer;
        display: block;
    }

    .right_box:hover .Previouspage {
        display: inline-block;
        cursor: pointer;
    }

    .right_box:hover .nextpage {
        display: inline-block;
        cursor: pointer;
    }

    .Previouspage {
        position: absolute;
        display: none;
        z-index: 100;
        cursor: pointer;
        top: -50px;
    }

    .Previouspage, .nextpage {
        > div {
            width: 50px;
            height: 50px;
            text-align: center;
        }

        img {
            transform: rotate(90deg);
        }
    }

    .nextpage {
        position: absolute;
        display: none;
        z-index: 100;
        top: 100%;
        cursor: pointer;
    }

    @include md {
        .media_warp {
            width: 100%;
            padding-top: 0;

            .right_box .right_Warp {
                margin-left: 0;
            }
        }

        .left_Warp {
            display: none;
        }

        .report {
            position: relative;
            top: 0;
            margin: 0 .5rem px2rem(40) auto;

        }

        .media_main {
            flex-direction: column;
        }

        .header {
            width: 9rem;
        }

        .Choice {
            margin: 0;
        }

        .right_box .Previouspage, .right_box .nextpage {
            display: inline-block;
            cursor: pointer;
            position: relative;
            top: 0;
        }
    }

    @include sm {

        .left_Warp {
            display: none;
        }
        .media_warp {
            width: 100%;
            padding-top: 0;

            .right_box .right_Warp {
                margin-left: 0;
            }
            .right p {
                width: 100% !important;
            }
        }

        .report {
            position: relative;
            margin: 0 auto px2rem(40);
        }

        .media_main {
            flex-direction: column;
        }

        .header {
            width: 9rem;
            padding-bottom: 3px;
        }

        .title {
            float: none;
            margin-bottom: 20px;
        }

        .Choice {
            margin: 0;
            float: none;

            .list_warp {
                display: block;
                text-align: center;

                li {
                    display: inline-block;
                }

                li:last-child {
                    margin: 0;
                }
            }
        }

        .right_box .Previouspage, .right_box .nextpage {
            display: inline-block;
            cursor: pointer;
            position: relative;
            top: 0;
        }
    }
</style>