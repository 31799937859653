<template>
    <div class="Review" :style="bgimg" :class="$route.params.lang">
        <div class="review-container">
            <div class="titles">
                <h3>{{$t('Pastreview.Titke')}}</h3>
            </div>
            <div class="headers">
                <ul class="header_list">
                    <li>
                        <h3 v-html="$t('Pastreview.HeaderList.Title0')"></h3>
                        <p>{{$t('Pastreview.HeaderList.ParticularYear0')}}</p>
                    </li>
                    <li style="margin:0 4px">
                        <h3>{{$t('Pastreview.HeaderList.Title1')}}</h3>
                        <p>{{$t('Pastreview.HeaderList.ParticularYear1')}}</p>
                    </li>
                    <li>
                        <h3>{{$t('Pastreview.HeaderList.Title2')}}</h3>
                        <p>{{$t('Pastreview.HeaderList.ParticularYear2')}}</p>
                    </li>
                </ul>
            </div>
            <app-swiper class="warplist" style="width:100%; margin-left:0px;">
                <app-swiper-item class="box" style="width:100%;">
                    <ul class="list_warp">
                        <div class="list_item">
                            <div class="warp_img">
                                <img src="/images/19E52D0B.jpg" alt/>
                            </div>
                            <div class="warp_title">
                                <h3>{{$t('Pastreview.listWarp.warpTitle0')}}</h3>
                                <p>{{$t('Pastreview.listWarp.warpContent0')}}</p>
                                <div class="jt">
                                    <img src="/images/shangjiantou.png" alt/>
                                </div>
                            </div>
                        </div>
                        <div class="list_item" style="margin:0 4px">
                            <div class="warp_title">
                                <h3>{{$t('Pastreview.listWarp.warpTitle1')}}</h3>
                                <p>{{$t('Pastreview.listWarp.warpContent1')}}</p>
                                <div class="jts">
                                    <img src="/images/xiajiantou.png" alt/>
                                </div>
                            </div>
                            <div class="warp_img">
                                <img src="/images/27944CBD.jpg" alt/>
                            </div>
                        </div>
                        <div class="list_item">
                            <div class="warp_img">
                                <img src="/images/C51CB2D6.jpg" alt/>
                            </div>
                            <div class="warp_title">
                                <h3>{{$t('Pastreview.listWarp.warpTitle2')}}</h3>
                                <p>{{$t('Pastreview.listWarp.warpContent2')}}</p>
                                <div class="jt">
                                    <img src="/images/shangjiantou.png" alt/>
                                </div>
                            </div>
                        </div>
                    </ul>
                </app-swiper-item>
<!--                <app-swiper-item class="box" style="width:1322px;">-->
<!--                    <ul class="list_warp">-->
<!--                        <div class="list_item">-->
<!--                            <div class="warp_img">-->
<!--                                <img src="/images/pic_wagnjiehuigu_1@2x.png" alt/>-->
<!--                            </div>-->
<!--                            <div class="warp_title">-->
<!--                                <h3>{{$t('Pastreview.listWarp.warpTitle0')}}</h3>-->
<!--                                <p>{{$t('Pastreview.listWarp.warpContent0')}}</p>-->
<!--                                <div class="jt">-->
<!--                                    <img src="/images/shangjiantou.png" alt/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="list_item" style="margin:0 4px">-->
<!--                            <div class="warp_title">-->
<!--                                <h3>{{$t('Pastreview.listWarp.warpTitle1')}}</h3>-->
<!--                                <p>{{$t('Pastreview.listWarp.warpContent1')}}</p>-->
<!--                                <div class="jts">-->
<!--                                    <img src="/images/xiajiantou.png" alt/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="warp_img">-->
<!--                                <img src="/images/pic_wangjiehuigu_2@2x.png" alt/>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="list_item">-->
<!--                            <div class="warp_img">-->
<!--                                <img src="/images/pic_wangjiehuigu_3@2x.png" alt/>-->
<!--                            </div>-->
<!--                            <div class="warp_title">-->
<!--                                <h3>{{$t('Pastreview.listWarp.warpTitle2')}}</h3>-->
<!--                                <p>{{$t('Pastreview.listWarp.warpContent2')}}</p>-->
<!--                                <div class="jt">-->
<!--                                    <img src="/images/shangjiantou.png" alt/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </ul>-->
<!--                </app-swiper-item>-->
            </app-swiper>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                bgimg: {
                    backgroundImage: "url(/images/img_bg_2@2x.png)",
                    backgroundRepeat: "no-repeat",
                },
            }
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    .Review {
        width: 100%;
        background-position-y: 460px;
        
        &.en{
            .list_warp .list_item .warp_title h3 {
                font-size: 18px;
                line-height: 23px;
            }
        }
    }

    .review-container {
        margin: auto;
        padding-top: px2rem(60);
        $width: px2rem((374 + 38) * 2);
        width: calc(#{$width} + 496px);
    }

    .titles {
        @include titles();

        line-height: 32px;
        margin-bottom: 42px;
        padding-bottom: 3px;

        h3 {
            font-size: 26px;
            font-weight: 600;
            color: #333333;
        }
    }

    .headers {
        width: 100%;
        // margin: auto;
        .header_list {
            width: 100%;
            overflow: hidden;
            background: #FAD214;
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;

            li {
                width: 100%;
                flex: 1;
                height: 120px;
                float: left;
                display: flex;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                flex-direction: column;
                background: rgba(35, 30, 4, 1);
                align-items: center;
                justify-content: center;
                text-align: center;

                h3 {
                    font-size: 28px;
                    font-weight: 500;
                    color: rgba(250, 210, 20, 1);
                    line-height: 42px;
                }

                >>> p {
                    font-size: 18px;
                    font-weight: 400;
                    color: rgba(250, 210, 20, 1);
                    line-height: 20px;
                }
            }
        }
    }

    .bjphone {
        width: 100%;
        height: 353px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .box {
        width: 70%;
        /*height: 524px;*/
        // background: saddlebrown;
    }


    #list {
        margin-left: 0px;
    }

    .list_warp {
        display: flex;
        width: 100%;
        cursor: pointer;

        .list_item {
            position: relative;
            display: flex;
            flex-direction: column;
            display: block;
            width: 100%;
            // flex: 1;
            .warp_img {
                width: 100%;
                height: 265px;

                img {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                }
            }

            .warp_title {
                flex: 1;
                width: 100%;
                height: 265px;
                padding: 43px 45px 45px 41px;
                box-sizing: border-box;
                background: #FAD214;
                position: relative;

                h3 {
                    font-size: 24px;
                    font-weight: 500;
                    color: rgba(51, 51, 51, 1);
                    line-height: 40px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(66, 66, 66, 1);
                    line-height: 26px;
                    margin-top: 10px;
                }
            }

            .jts {
                position: absolute;
                left: 50%;
                top: 100%;
                margin-top: -1px;
                margin-left: -26.5px;
            }

            .jt {
                position: absolute;
                left: 50%;
                margin-left: -26.5px;
                margin-bottom: -4px;
                bottom: 100%;
            }
        }
    }

    @include md {
        .review-container {
            width: 9rem;
        }

        .Review {
            .headers {
                .header_list {
                    li {
                        height: 90px;

                        h3 {
                            font-size: 24px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .list_warp {
            .list_item {
                .warp_title {
                    padding-top: 30px;

                    h3 {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    
    .en{
        .list_warp .list_item:not(:nth-child(2)) .warp_title{
            padding-top: 5px;
        }
        
        .list_warp .list_item .warp_title p{
            margin-top: 3px;
        }
    }

    @include sm {
        .review-container {
            width: 9rem;
        }

        .Review {
            .headers {
                .header_list {
                    li {
                        height: auto;
                        padding: 5px 0;

                        h3 {
                            font-size: 16px;
                            line-height: 30px;
                        }

                        p {
                            font-size: 10px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        .list_warp {
            flex-direction: column;

            .list_item {
                margin: 0 auto !important;
                border-bottom: 1px solid #dddddd;

                .warp_title {
                    padding: 20px px2rem(40);
                    height: auto;

                    /*h3 {*/
                    /*    font-size: 16px;*/
                    /*    line-height: 20px;*/
                    /*}*/

                    /*p {*/
                    /*    font-size: 8px;*/
                    /*    line-height: 16px;*/
                    /*}*/
                }
            }
        }
    }
</style>