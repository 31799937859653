<template>
    <div class="Home">
        <div>
            <!-- <Header/> -->
            <Banner/>
            <Sailingintroduction/>
            <Exhibitionintroduction id="duction"/>
            <Exhibitionactivities id="activites"/>
            <Pastreview id="Pastreview"/>
            <Review id="Review"/>
            <Media id="Media"/>
            <MediaCooperative id="CooperativeMedia"/>
            <Footer/>
        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Header from '../../components/header' //头部
    import Banner from './children/Rotationchart'  //焦点图
    import Sailingintroduction from './children/Sailingintroduction'  //起航咨询
    import Exhibitionintroduction from './children/Exhibitionintroduction'  //展会介绍
    import Exhibitionactivities from './children/Exhibitionactivities' //展会活动
    import Pastreview from './children/Pastreview' //展品范围
    import Review from './children/Review' //往届回顾
    import Media from './children/Mediainformation' //媒体资讯
    import MediaCooperative from './children/CooperativeMedia' //媒体合作
    import Footer from './children/Footer' //页脚
    export default {
        components: {
            Header,
            Banner,
            Sailingintroduction,
            Exhibitionintroduction,
            Exhibitionactivities,
            Pastreview,
            Review,
            Media,
            MediaCooperative,
            Footer
        },
        data() {
            return {}
        },

        methods: {}
    }
</script>

<style lang="less" scoped>
    html, body {
        min-width: 100%;
    }

    /*@media only screen and (min-width: 100%) {*/
    /*    html, body {*/
    /*        min-width: 100%;*/
    /*    }*/
    /*}*/


</style>