<template>
    <div class="Exhibition_Warp" :style="bgimgs">
        <div class="title">
            <div class="contents">
                <div class="titles">
                    <h3>{{$t('ExhibitionIntroduction.Titke')}}</h3>
                </div>
                <div class="Video_introduction">
                    <div class="left">
                        <video src="https://vid.conmart.cn/expo/Conmart-Expo-2020.mp4" v-if="$i18n.locale === 'zh'"
                               controls="controls"
                               class="vid"
                               poster="/images/191115131530.png"
                        >
                        </video>
                        <video src="https://vid.conmart.cn/expo/Conmart-Expo-2020.mp4" v-if="$i18n.locale === 'en'"
                               controls="controls"
                               class="vid"
                               poster="/images/191115131530.png"
                        >
                        </video>
                        <!--                               muted="true"-->
                    </div>
                    <div class="right">
                        <div class="box_s">
                            <h4>{{$t('ExhibitionIntroduction.PromotionalVideo')}}</h4>
                            <p>{{$t('ExhibitionIntroduction.PropagandaContent')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                bgimgs: {
                    backgroundImage: "url(/images/img_bg_1@2x.png)",
                    backgroundRepeat: "no-repeat",
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .Exhibition_Warp {
        width: 100%;
        min-height: 752px;
        background-position-y: 256px;
        box-sizing: border-box;
        text-align: center;

        .title {
            margin: auto;
            padding-top: px2rem(60);
            $width: px2rem((374 + 38) * 2);
            width: calc(#{$width} + 496px);

            .contents {
                text-align: left;

                .titles {
                    @include titles();

                    line-height: 32px;
                    margin-bottom: px2rem(42);

                    h3 {
                        font-size: 26px;
                        font-weight: 600;
                        color: #333;
                    }
                }

                .Video_introduction {
                    .left {
                        float: left;
                    }

                    .right {
                        float: left;
                    }
                }
            }
        }

        .left {
            width: 60%;

            .vid {
                width: 100%;
                height: 456px;

                @include sm {
                    height: auto;
                }
                vertical-align: top;

            }
        }

        .right {
            width: 40%;
            height: 454px;
            background: rgba(246, 246, 246, 1);
            padding: 30px;
            padding: 32px 32px 91px 85px;
            box-sizing: border-box;
            margin-top: 3px;
            margin-left: -1px;

            .box_s {
                width: 315px;
                height: 377px;
                border: 3px solid rgba(102, 102, 102, 1);
                padding: 50px 51px 130px 51px;
                box-sizing: border-box;

                h4 {
                    font-size: 18px;
                    font-weight: bold;
                    color: rgba(102, 102, 102, 1);
                    margin-bottom: 18px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 24px;
                    text-align: left;
                    position: relative;

                    &::after {
                        content: "";
                        width: 69px;
                        height: 3px;
                        background: #666666;
                        position: absolute;
                        left: -102px;
                        top: -30px;
                    }
                }

            }
        }
    }

    @include md {
        .Exhibition_Warp {
            .title {
                width: 9rem;
                padding-right: 0;

                .contents {
                    .Video_introduction {
                        .left, .right {
                            float: none;
                            display: block;
                            width: 100%;
                            height: auto;
                        }

                        .right {
                            padding-bottom: 20px;

                            .box_s {
                                width: 100%;
                                height: auto;
                                padding-bottom: px2rem(130);
                            }
                        }
                    }
                }
            }
        }
    }

    @include sm {
        .Exhibition_Warp {
            .title {
                .contents {
                    .Video_introduction {
                        .left, .right {
                            float: none;
                            display: block;
                            width: 100%;
                            height: auto;
                        }

                        .right {
                            padding-bottom: 20px;

                            .box_s {
                                width: 100%;
                                height: auto;
                                padding-bottom: px2rem(130);
                            }
                        }
                    }
                }
            }
        }
    }
</style>
