var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Cooperative" }, [
    _c("div", { staticClass: "core" }, [
      _c("div", { staticClass: "titles" }, [
        _c("h3", [
          _vm._v(_vm._s(_vm.$t("homeTitle.listItem0.CooperativeMedia")))
        ])
      ]),
      _c("div", { staticClass: "Cooperative_main" }, [
        _c("div", { staticClass: "swiper-father" }, [
          _c("div", { staticClass: "swiper-container CooperativeMedia" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.newData, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "swiper-slide swiper" },
                  [
                    _c(
                      "a",
                      {
                        ref: "nofollow",
                        refInFor: true,
                        staticClass: "item-container",
                        attrs: { target: "_blank", href: item.href }
                      },
                      [_c("img", { attrs: { src: item.img, alt: item.href } })]
                    )
                  ]
                )
              }),
              0
            )
          ]),
          _c("div", {
            staticClass: "swiper-button-prev",
            staticStyle: { top: "73%" }
          }),
          _c("div", {
            staticClass: "swiper-button-next",
            staticStyle: { top: "73%" }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }