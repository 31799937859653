var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exhibits" }, [
    _c("div", { staticClass: "title-container" }, [
      _c("div", { staticClass: "title" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("ScopeOfExhibits.Titke")))])
      ])
    ]),
    _c("div", { staticClass: "warp_contina" }, [
      _c("div", { staticClass: "left" }, [
        _c("img", { attrs: { src: "/images/2B18837D.jpg", alt: "" } }),
        _c("div", { staticClass: "introduces" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ListofExhibits0")))])
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "right_top" }, [
          _c("ul", { staticClass: "toplist" }, [
            _c("li", { staticClass: "toplist-item list" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "show-item" }, [
                  _c("div", { staticClass: "warp" }, [
                    _vm._m(0),
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ExhibitsTeitle0")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ListofExhibits1")))
                    ])
                  ])
                ])
              ])
            ]),
            _c("li", { staticClass: "toplist-item list" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "show-item" }, [
                  _c("div", { staticClass: "warp" }, [
                    _vm._m(1),
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ExhibitsTeitle1")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ListofExhibits2")))
                    ])
                  ])
                ])
              ])
            ]),
            _c("li", { staticClass: "toplist-item list" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "show-item" }, [
                  _c("div", { staticClass: "warp" }, [
                    _vm._m(2),
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ExhibitsTeitle2")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ListofExhibits3")))
                    ])
                  ])
                ])
              ])
            ]),
            _c("li", { staticClass: "toplist-item toplist-bottom-item list" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "show-item" }, [
                  _c("div", { staticClass: "warp" }, [
                    _vm._m(3),
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ExhibitsTeitle3")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ListofExhibits4")))
                    ])
                  ])
                ])
              ])
            ]),
            _c("li", { staticClass: "toplist-item toplist-bottom-item list" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "show-item" }, [
                  _c("div", { staticClass: "warp" }, [
                    _vm._m(4),
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ExhibitsTeitle4")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ListofExhibits5")))
                    ])
                  ])
                ])
              ])
            ]),
            _c("li", { staticClass: "toplist-item toplist-bottom-item list" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "show-item" }, [
                  _c("div", { staticClass: "warp" }, [
                    _vm._m(5),
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ExhibitsTeitle5")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("ScopeOfExhibits.ListofExhibits6")))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgurl" }, [
      _c("img", { attrs: { src: "/images/498AA914.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgurl" }, [
      _c("img", { attrs: { src: "/images/DDC93373.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgurl" }, [
      _c("img", { attrs: { src: "/images/AA5F7332.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgurl" }, [
      _c("img", { attrs: { src: "/images/22DE039F.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgurl" }, [
      _c("img", { attrs: { src: "/images/B1083DF2.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgurl" }, [
      _c("img", { attrs: { src: "/images/xsadr12d.png", alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }