<template>
    <div class="Sailing_warp">
        <div class="Backgroundmaps" :style="bg">
            <div class="bg_text">
                <h1>{{$t('SetSail.title')}}</h1>
                <div class="text_warp">
                    <p>{{$t('SetSail.SailingContents.Contents0')}}</p>
                    <p>{{$t('SetSail.SailingContents.Contents1')}}</p>
                    <p>{{$t('SetSail.SailingContents.Contents2')}}</p>
                    <p>{{$t('SetSail.SailingContents.Contents3')}}</p>
                </div>
            </div>
            <div class="Icons">
                <ul class="list_icon">
                    <li>
                        <img :src="icon[0]" alt="" class="iconi">
                    </li>
                    <li>
                        <img :src="icon[1]" alt="" class="iconi">
                    </li>
                    <li>
                        <img :src="icon[2]" alt="" class="iconi">
                    </li>
                    <li>
                        <img :src="icon[3]" alt="" class="iconi">
                    </li>
                    <li>
                        <img :src="icon[4]" alt="" class="iconi">
                    </li>
                    <li>
                        <img :src="icon[5]" alt="" class="iconi">
                    </li>
                </ul>
            </div>
            <div class="center">
                <div class="introduce_text">
                    <ul class="left_text">
                        <li class="list">
                            <div class="box_img box-chanzhan" :style="{
                                backgroundImage: `url('${pickerMain[0]}')`
                            }">
                                <!--                                <img src="/images/chanzhan.png" alt="">-->
                            </div>
                            <div class="text_con">
                                <h3>{{$t('SetSail.textCon.Exhibitors')}}</h3>
                                <p>{{$t('SetSail.textCon.ExhibitionContent')}}</p>
                                <a :href="`/${$i18n.locale}/article/1/why`" class="Learnmore">{{$t('SetSail.textCon.LearnMore')}}</a>
                            </div>
                        </li>
                        <li class="list">
                            <div class="box_img box-guanzhan" :style="{
                                backgroundImage: `url('${pickerMain[1]}')`
                            }">
                                <!--<img src="/images/guanzhan.png" alt="">-->
                            </div>
                            <div class="text_con">
                                <h3>{{$t('SetSail.textCon.Exhibition')}}</h3>
                                <p>{{$t('SetSail.textCon.ExhibitionContents')}}</p>
                                <a :href="`/${$i18n.locale}/article/2/why`" class="Learnmore">{{$t('SetSail.textCon.LearnMore')}}</a>
                            </div>
                        </li>
                    </ul>
                    <a href="javaScript:;"> <img :src="pickerMain[2]" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                bg: {
                    backgroundImage: "url(/images/bg.png)",
                    backgroundRepeat: "no-repeat",
                },
            }
        },
        computed: {
            icon() {
                return {
                    'zh': [
                        require('@/assets/images/home/module/icon1@2x.zh.png'),
                        require('@/assets/images/home/module/icon2@2x.zh.png'),
                        require('@/assets/images/home/module/icon3@2x.zh.png'),
                        require('@/assets/images/home/module/icon4@2x.zh.png'),
                        require('@/assets/images/home/module/icon5@2x.zh.png'),
                        require('@/assets/images/home/module/icon6@2x.zh.png'),
                    ],
                    'en': [
                        require('@/assets/images/home/module/icon1@2x.en.png'),
                        require('@/assets/images/home/module/icon2@2x.en.png'),
                        require('@/assets/images/home/module/icon3@2x.en.png'),
                        require('@/assets/images/home/module/icon4@2x.en.png'),
                        require('@/assets/images/home/module/icon5@2x.en.png'),
                        require('@/assets/images/home/module/icon6@2x.en.png'),
                    ]
                }[this.$route.params.lang]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .Sailing_warp {
        width: 100%;
        position: relative;

        .Backgroundmaps {
            display: block;
            margin: auto;
            padding-top: 40px;
        }
    }

    .bg_text {
        width: 100%;

        h1 {
            font-size: 36px;
            font-weight: 500;
            color: #333333;
            text-align: center;

        }

        .text_warp {
            width: 80%;
            margin: auto;
            margin-top: 50px;
            margin-bottom: 50px;

            p {
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                line-height: 30px;
            }
        }
    }

    .Icons {
        margin: auto;

        .list_icon {
            text-align: center;

            li {
                display: inline-block;
                width: 90px;
                margin: px2rem(31);

                img {
                    width: 100%;
                }
            }
        }
    }

    .introduce_text {
        margin-top: 113px;
        display: inline-block;
        overflow: hidden;
        text-align: left;

        .left_text {
            float: left;

            .list {
                width: px2rem(374);
                min-height: 372px;
                margin-right: px2rem(38);
                transition: .2s linear;
                transition-property: transform, box-shadow;
                overflow: hidden;
                float: left;

                &:hover {
                    box-shadow: 3px 5px 17px #888888;
                    transform: translate(0, -5px);
                    z-index: 2;
                }

                .box_img {
                    width: 100%;
                    min-height: 142px;
                    transition: all 0.5s;
                    overflow: hidden;

                    &.box-guanzhan {
                        /*background-image: url('../../../assets/images/guanzhan.jpg');*/
                        background-size: cover;
                        background-position: center;
                    }

                    &.box-chanzhan {
                        /*background-image: url('../../../assets/images/canzhan.jpg');*/
                        background-size: cover;
                        background-position: center;
                    }

                    &:hover {
                        transform: scale(1.2);
                    }
                }

                .text_con {
                    padding: 22px 23px 0 22px;
                    box-sizing: border-box;
                    position: relative;
                    border: 1px solid rgba(153, 153, 153, 1);
                    border-top: none;
                    min-height: 232px;
                    overflow: hidden;
                    padding-bottom: 10px;

                    h3 {
                        font-size: 16px;
                        font-weight: 600;
                        color: rgba(51, 51, 51, 1);
                        margin: 10px 0;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                        line-height: 24px;
                    }

                    .Learnmore {
                        display: block;
                        margin-top: px2rem(20);
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                    }
                }
            }
        }

        a {
            display: block;
            transition: all 0.5s;
            float: left;
            overflow: hidden;

            img {
                transition: all 0.5s;
                height: 372px;
                vertical-align: top;
            }

            &:hover img {
                transform: scale(1.1);
            }
        }
    }

    @include lg {

    }

    @include md {
        .introduce_text {
            text-align: center;

            .left_text {
                float: none;
                width: 100%;
                margin-bottom: 40px;

                .list {
                    display: inline-block;
                    float: none;
                    text-align: left;
                    width: px2rem(844);
                }

                .list:last-child {
                    margin-right: 0;
                }
            }

            > a {
                float: none;
                display: inline-block;
                width: px2rem(844 * 2 + 38);

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    @include sm {
        .introduce_text {
            text-align: center;

            .left_text {
                margin: 0;

                .list {
                    margin: 0 0 px2rem(50);
                    float: none;
                    width: 9rem;

                    .box_img {
                        height: 142 / 374 * 9rem;
                    }
                }
            }
        }
    }
</style>