<template>
    <div class="Cooperative">
        <div class="core">
            <div class="titles">
                <h3>{{$t('homeTitle.listItem0.CooperativeMedia')}}</h3>
            </div>
            <div class="Cooperative_main">
                <div class="swiper-father">
                    <div class="swiper-container CooperativeMedia">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide swiper" v-for="(item,index) in newData" :key="index">
                                <a class="item-container" target="_blank" :href="item.href" ref="nofollow">
                                    <img :src="item.img" :alt="item.href">
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- 我们把swiper 的箭头放到swiper 外面 -->
                    <div class="swiper-button-prev" style="top:73%"></div>
                    <div class="swiper-button-next" style="top:73%"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            let path = '/images/CooperativeMedia';
            return {
                prev: {
                    backgroundImage: "url(/images/Exhibitorsservice/left.png)",
                    backgroundRepeat: "no-repeat",
                },
                next: {
                    backgroundImage: "url(/images/Exhibitorsservice/right.png)",
                    backgroundRepeat: "no-repeat",
                },
                newData: [
                    /*英文*/
                    {img: `${path}/NBMCW Logo.jpg`, href: 'http://www.nbmcw.com/'},
                    {img: `${path}/CPI-logo.png`, href: 'https://www.cpi-worldwide.com'},
                    {img: `${path}/CM-LOGO FINAL Golden.jpg`, href: 'http://www.constructionmirror.com/'},
                    {img: `${path}/TEXHO.jpg`, href: 'https://t-magazine.ru/'},
                    {img: `${path}/logo_vm1.jpg`, href: 'https://www.via-mobilis.com'},
                    {img: `${path}/CT logo-1.png`, href: 'http://constructiontimes.co.in'},
                    {img: `${path}/ET logo-1.png`, href: 'http://equipmenttimes.in'},
                    {img: `${path}/TODLogo.png`, href: 'https://www.constructiontechnology.in'},
                    {img: `${path}/gineersnow.png`, href: 'https://gineersnow.com'},
                    /*中文*/
                    {img: `${path}/21sun.jpg`, href: 'http://www.21-sun.com'},
                    {img: `${path}/d1gcjxw.jpg`, href: 'https://www.d1cm.com'},
                    {img: `${path}/gcjxzx.jpg`, href: 'http://www.cmol.com'},
                    {img: `${path}/hc.jpg`, href: 'http://www.cm.hc360.com'},
                    {img: `${path}/hqpsj.jpg`, href: 'http://www.ycrusher.com'},
                    {img: `${path}/jdzj.jpg`, href: 'http://www.jdzj.com'},
                    {img: `${path}/kdw.jpg`, href: 'https://www.mining120.com'},
                    {img: `${path}/lmjx.jpg`, href: 'http://www.zcwz.com'},
                    {img: `${path}/tiejia.jpg`, href: 'https://www.cehome.com'},
                    {img: `${path}/tsw.jpg`, href: 'http://www.ccmn.net'},
                    {img: `${path}/whgcjx.jpg`, href: 'http://www.91cm.com'},
                    {img: `${path}/wjjw.jpg`, href: 'http://www.wjjw.cn'},
                    {img: `${path}/yf.jpg`, href: 'https://www.yingfeng365.com'},
                    {img: `${path}/yyw.jpg`, href: 'http://www.zgyyw.cc'},
                    {img: `${path}/zhzcw.jpg`, href: 'http://www.zcwz.com'},
                ]
            }
        },
        mounted() {
            var mySwiper = new Swiper('.CooperativeMedia', {
                direction: 'horizontal',
                loop: true,
                // observer: true,
                // observeParents: true,
                autoplay: true,
                slidesPerView: 5,
                delay: 2000,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        },
        methods: {
            Towardstheleft() {
                this.mySwiper.slidePrev();
            },
            Towardstheright() {
                this.mySwiper.slideNext()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        top: 61%;
        width: 25px;
        height: 41px;
        margin-top: -23px;
        margin-right: 10px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        top: 61%;
        left: -72px;
        right: auto;
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        top: 61%;
        right: -72px;
        left: auto;
    }

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 61%;
        width: 25px;
        height: 41px;
        margin-top: -23px;
        margin-right: 10px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .Cooperative {
        width: 100%;
        padding-bottom: 50px;
    }

    .core {
        margin: auto;
        position: relative;

        @include design-width-setting;
    }

    .titles {
        @include titles();

        line-height: 32px;
        margin-bottom: 42px;
        padding-bottom: 3px;
        padding-top: 50px;

        h3 {
            font-size: 26px;
            font-weight: 600;
        }
    }

    .Cooperative_main {
        overflow: hidden;

        .swiper {
            overflow: hidden;

            .item-container {
                border: 1px solid #dedede;
                height: 156px;
                width: 200px;
                box-sizing: border-box;
                display: block;
                text-align: center;
                display: table-cell;
                vertical-align: middle;

                img {
                    width: 100%;
                    height: 80px;
                    object-fit: contain;
                }
            }
        }
    }

    .Towardstheleft {
        width: 22px;
        height: 44px;
        position: absolute;
        left: -90px;
        top: 430px;
    }

    .Towardstheright {
        width: 22px;
        height: 44px;
        position: absolute;
        right: -90px;
        top: 430px;
    }

    .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 14px;
        height: 14px;
        margin-top: -11px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 25px;
        height: 41px;
        margin-top: -23px;
        margin-right: 10px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .nav-arrow {
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    @include md {
        .swiper-button-prev, .swiper-button-next {
            display: none;
        }
    }
</style>
