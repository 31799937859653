var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Review", class: _vm.$route.params.lang, style: _vm.bgimg },
    [
      _c(
        "div",
        { staticClass: "review-container" },
        [
          _c("div", { staticClass: "titles" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("Pastreview.Titke")))])
          ]),
          _c("div", { staticClass: "headers" }, [
            _c("ul", { staticClass: "header_list" }, [
              _c("li", [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("Pastreview.HeaderList.Title0"))
                  }
                }),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("Pastreview.HeaderList.ParticularYear0"))
                  )
                ])
              ]),
              _c("li", { staticStyle: { margin: "0 4px" } }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("Pastreview.HeaderList.Title1")))
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("Pastreview.HeaderList.ParticularYear1"))
                  )
                ])
              ]),
              _c("li", [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("Pastreview.HeaderList.Title2")))
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("Pastreview.HeaderList.ParticularYear2"))
                  )
                ])
              ])
            ])
          ]),
          _c(
            "app-swiper",
            {
              staticClass: "warplist",
              staticStyle: { width: "100%", "margin-left": "0px" }
            },
            [
              _c(
                "app-swiper-item",
                { staticClass: "box", staticStyle: { width: "100%" } },
                [
                  _c("ul", { staticClass: "list_warp" }, [
                    _c("div", { staticClass: "list_item" }, [
                      _c("div", { staticClass: "warp_img" }, [
                        _c("img", {
                          attrs: { src: "/images/19E52D0B.jpg", alt: "" }
                        })
                      ]),
                      _c("div", { staticClass: "warp_title" }, [
                        _c("h3", [
                          _vm._v(
                            _vm._s(_vm.$t("Pastreview.listWarp.warpTitle0"))
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("Pastreview.listWarp.warpContent0"))
                          )
                        ]),
                        _c("div", { staticClass: "jt" }, [
                          _c("img", {
                            attrs: { src: "/images/shangjiantou.png", alt: "" }
                          })
                        ])
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "list_item",
                        staticStyle: { margin: "0 4px" }
                      },
                      [
                        _c("div", { staticClass: "warp_title" }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.$t("Pastreview.listWarp.warpTitle1"))
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("Pastreview.listWarp.warpContent1"))
                            )
                          ]),
                          _c("div", { staticClass: "jts" }, [
                            _c("img", {
                              attrs: { src: "/images/xiajiantou.png", alt: "" }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "warp_img" }, [
                          _c("img", {
                            attrs: { src: "/images/27944CBD.jpg", alt: "" }
                          })
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "list_item" }, [
                      _c("div", { staticClass: "warp_img" }, [
                        _c("img", {
                          attrs: { src: "/images/C51CB2D6.jpg", alt: "" }
                        })
                      ]),
                      _c("div", { staticClass: "warp_title" }, [
                        _c("h3", [
                          _vm._v(
                            _vm._s(_vm.$t("Pastreview.listWarp.warpTitle2"))
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("Pastreview.listWarp.warpContent2"))
                          )
                        ]),
                        _c("div", { staticClass: "jt" }, [
                          _c("img", {
                            attrs: { src: "/images/shangjiantou.png", alt: "" }
                          })
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }