<template>
    <div class="Rotaionchart">
        <app-swiper :enablePagination="true" loop style="width: 100%" class="warp_banner">
            <app-swiper-item class="box" v-for="banner in bannerList" v-key="banner">
                <div class="box-item">
                    <img :src="banner" alt/>
                </div>
            </app-swiper-item>
<!--            <app-swiper-item class="box">-->
<!--                <div class="box-item">-->
<!--                    <img :src="bannerList[3]" alt/>-->
<!--                </div>-->
<!--            </app-swiper-item>-->
<!--            <app-swiper-item class="box">-->
<!--                <div class="box-item">-->
<!--                    <img :src="bannerList[2]" alt/>-->
<!--                </div>-->
<!--            </app-swiper-item>-->
<!--            <app-swiper-item class="box">-->
<!--                <div class="box-item">-->
<!--                    <img :src="bannerList[1]" alt/>-->
<!--                </div>-->
<!--            </app-swiper-item>-->
<!--            <app-swiper-item class="box">-->
<!--                <div class="box-item">-->
<!--                    <img :src="bannerList[0]" alt/>-->
<!--                    <div class="position" :class="$route.params.lang">-->
<!--                        <a :href="link.join" class="banner-link"></a>-->
<!--                        <a :href="link.visit" class="banner-link"></a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </app-swiper-item>-->
        </app-swiper>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        data() {
            return {
                link: {
                    visit: `/${this.$route.params.lang}/exhibitorsService#exhibition`,
                    join: `/${this.$route.params.lang}/buyerservice#RegistrationOfExhibitors`,
                },
            };
        },
        mounted() {
            // console.log(this.bannerList);
        },
        computed: {
            isNewYear() {
                return moment().isBetween(moment('2020-01-19'), moment('2020-02-03'))
            }
        }
    };
</script>
<style lang="scss" scoped>
    .Rotaionchart {
        width: 100%;

        .warp_banner {
            width: 100%;

            .box {
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 14px;
        height: 14px;
        margin-top: -11px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 14px;
        height: 14px;
        margin-top: -10px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-pagination > .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        /* border-radius: 100%; */
        background: #000;
        opacity: 0.2;
    }

    .swiper-pagination > .swiper-pagination-bullet-active {
        opacity: 1;
        background: #007aff;
    }

    /* @media screen and (max-width: 1024px) {*/
    /*   .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {*/
    /*      bottom: 10px;*/
    /*      left: 0;*/
    /*      width: 89%;*/
    /*  }*/
    /* }*/
    /*  @media screen and (max-width: 767px) {*/
    /*    .Rotaionchart {*/
    /*        width: 100%;*/
    /*        padding-top: 127px;*/
    /*    }*/
    /*}*/

    .box-item {
        position: relative;

        .position {
            position: absolute;
            top: 449 / 700 * 100%;
            width: 100%;
            height: 0;
            text-align: center;
            margin-top: -2px;

            &.en {
                top: 71.14286%;
            }

            > a {
                display: inline-block;
                margin: 0 px2rem(68 / 2);
                width: px2rem(254);
                height: px2rem(73);
                /*background: yellow;*/
            }
        }
    }
</style>
