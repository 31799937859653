<template>
    <div class="Exhibitionactivities" :class="$route.params.lang">
        <div class="title">
            <h2>{{$t('ExhibitionActivities.Title')}}</h2>
        </div>
        <div class="list_warp">
            <ul class="list">
                <li class="list_item  list_item0">
                    <a href="javaScript:void(0);" class="item01 item">
                        <img src="/images/18E12A67.jpg" alt="">
                        <div class="show">
                            <h2 class="h2">{{$t('ExhibitionActivities.ActivityList.Title0')}}</h2>
                            <p>{{$t('ExhibitionActivities.ActivityList.ListItem0')}}</p>
                        </div>
                    </a>
                    <div class="learn-more">
                        <a :href="`/${$route.params.lang}/conferenceactivities#MainActivity`">
                            <div class="arrow"></div>
                            <span>{{$t('ExhibitionActivities.ActivityList.LearnMore')}}</span>
                        </a>
                    </div>
                </li>
                <li class="list_item list_item1">
                    <a href="javaScript:;" class="item02 item">
                        <img src="/images/1DA263B7.jpg" alt="">
                        <div class="show">
                            <h2 class="h2">{{$t('ExhibitionActivities.ActivityList.Title1')}}</h2>
                            <p>{{$t('ExhibitionActivities.ActivityList.ListItem1')}}</p>
                        </div>
                    </a>
                    <div class="learn-more">
                        <a :href="`/${$route.params.lang}/conferenceactivities#MainActivity`">
                            <div class="arrow"></div>
                            <span>{{$t('ExhibitionActivities.ActivityList.LearnMore')}}</span>
                        </a>
                    </div>
                </li>
                <li class="list_item list_item2">
                    <a href="javaScript:;" class="item03 item">
                        <img src="/images/8182285F.jpg" alt="">
                        <div class="show">
                            <h2 class="h2">{{$t('ExhibitionActivities.ActivityList.Title2')}}</h2>
                            <p>{{$t('ExhibitionActivities.ActivityList.ListItem2')}}</p>
                        </div>
                    </a>
                    <div class="learn-more">
                        <a :href="`/${$route.params.lang}/conferenceactivities#MainActivity`">
                            <div class="arrow"></div>
                            <span>{{$t('ExhibitionActivities.ActivityList.LearnMore')}}</span>
                        </a>
                    </div>
                </li>
                <li class="list_item list_item3">
                    <a href="javaScript:;" class="item04 item">
                        <img src="/images/52815E65.jpg" alt="">
                        <div class="show">
                            <h2 class="h2">{{$t('ExhibitionActivities.ActivityList.Title3')}}</h2>
                            <p>{{$t('ExhibitionActivities.ActivityList.ListItem3')}}</p>
                        </div>
                    </a>
                    <div class="learn-more">
                        <a :href="`/${$route.params.lang}/conferenceactivities#MainActivity`">
                            <div class="arrow"></div>
                            <span>{{$t('ExhibitionActivities.ActivityList.LearnMore')}}</span>
                        </a>
                    </div>
                </li>
                <li class="list_item list_item4">
                    <a href="javaScript:;" class="item05 item">
                        <img src="/images/668282A0.jpg" alt="">
                        <div class="show">
                            <h2 class="h2">{{$t('ExhibitionActivities.ActivityList.Title4')}}</h2>
                            <p>{{$t('ExhibitionActivities.ActivityList.ListItem4')}}</p>
                        </div>
                    </a>
                    <div class="learn-more">
                        <a :href="`/${$route.params.lang}/conferenceactivities#MainActivity`">
                            <div class="arrow"></div>
                            <span>{{$t('ExhibitionActivities.ActivityList.LearnMore')}}</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {}
</script>

<style lang="scss" scoped>
    .Exhibitionactivities {
        padding-top: px2rem(10);
        $width: px2rem((374 + 38) * 2);
        width: calc(#{$width} + 496px);
        margin: 0 auto;

        &.en {
            .list_warp .list_item .item .show p{
                line-height: 1.2;
                font-size: 14px;
            }

            .list_warp .list_item .item .show .h2{
                height: auto;
                font-size: 16px;
                line-height: 1.2;
            }
        }
    }

    .title {
        @include titles();

        line-height: 32px;
        margin-bottom: px2rem(48);

        h2 {
            font-size: 26px;
            font-weight: 600;
            color: #333;
        }
    }

    .list_warp {
        width: 100%;
        text-align: center;

        .list {
            overflow: hidden;
            display: inline-block;
            text-align: left;
        }

        .list_item {
            float: left;

            @include sm {
                float: none;
            }
            height: px2rem(352);
            margin: 0 2px 90px;
            position: relative;

            &:hover {
                .item .show {
                    top: 0;
                    margin-top: 0;
                }

                img {
                    filter: blur(10px);
                }

                .item .show .h2 {
                    margin-top: 20px;
                    top: px2rem(20);
                    color: rgba(250, 210, 20, 1);
                }
                .item .show p{
                    display: block;
                }
                .learn-more {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0, 0);

                    .arrow {
                        transform: rotate(180deg);
                    }
                }
            }

            .item {
                width: 100%;
                height: 100%;
                display: block;
                cursor: default;
                position: relative;
                overflow: hidden;

                img {
                    vertical-align: top;
                    width: px2rem(252);
                }

                .show {
                    width: 100%;
                    height: 352px;
                    background: rgba(0, 0, 0, 1);
                    opacity: 0.7;
                    position: absolute;
                    left: 0;
                    top: px2rem(298);
                    padding: 0 px2rem(23);
                    box-sizing: border-box;
                    transition: all 0.5s ease-out;

                    .h2 {
                        height: 18px;
                        font-size: 20px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 1);
                        /*position: absolute;*/
                        top: px2rem(16);
                        text-align: center;
                        transition: all 0.5s ease-out;
                    }

                    p {
                        display: none;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(255, 227, 94, 1);
                        margin-top: 10px;
                        line-height: 28px;
                    }
                }
            }


            .learn-more {
                background: #FAD214;
                line-height: 30px;
                height: 80px;
                text-align: center;
                vertical-align: middle;
                opacity: 0;
                visibility: hidden;
                transform: translate(0, -5px);
                transition: .2s linear;
                font-size: 0;

                &:before {
                    content: '';
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                    width: 0;
                }

                span {
                    vertical-align: middle;
                    color: #333333;
                    font-size: 14px;
                }

                > a {
                    vertical-align: middle;
                    display: inline-block;
                    width: 100%;
                }

                .arrow {
                    display: block;
                    margin: 0 auto;
                    width: 15px;
                    height: 15px;
                    position: relative;
                    transform: rotate(0);
                    transition: transform .2s linear;
                    vertical-align: middle;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 50%;
                        height: 2px;
                        top: 80%;
                        padding-right: 1px;
                        background: #333333;
                        transform: rotate(45deg);
                        transform-origin: right top;
                    }

                    &:after {
                        right: 0;
                        padding-left: 1px;
                        transform: rotate(-45deg);
                        transform-origin: left top;
                    }
                }
            }

            @include lg {
                .item {
                    .show {
                        .h2 {
                            font-size: 16px;
                        }
                    }
                }
            }

            @include md {
                .item {
                    .show {
                        .h2 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        @include md {
            .list {
                display: block;

                .list_item {
                    height: auto;
                }

                .item {
                    .show {
                        top: 100%;
                        margin-top: -40px;

                        p {
                            margin-top: 40px;
                        }
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    .item02 {
        position: relative;
        overflow: hidden;

        .show1 {
            width: 100%;
            height: 352px;
            background: rgba(0, 0, 0, 1);
            opacity: 0.7;
            position: absolute;
            left: 0;
            top: 298px;
            padding: 0 46px 0 35px;
            box-sizing: border-box;
            transition: all 0.5s ease-out;

            .h2 {
                // width: 200px;
                height: 18px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                position: relative;
                top: 16px;
                text-align: center;
                transition: all 0.5s ease-out;
            }

            p {
                width: 183px;
                height: 64px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 227, 94, 1);
                margin-top: 162px;
                line-height: 28px;
            }
        }
    }

    .item03 {
        position: relative;
        overflow: hidden;

        .show2 {
            width: 100%;
            height: 352px;
            background: rgba(0, 0, 0, 1);
            opacity: 0.7;
            position: absolute;
            left: 0;
            top: 298px;
            padding: 0 46px 0 35px;
            box-sizing: border-box;
            transition: all 0.5s ease-out;

            .h2 {
                // width: 200px;
                height: 18px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                position: absolute;
                top: 16px;
                text-align: center;
                transition: all 0.5s ease-out;
            }

            p {
                width: 183px;
                height: 64px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 227, 94, 1);
                margin-top: 162px;
                line-height: 28px;
            }
        }
    }

    .item04 {
        position: relative;
        overflow: hidden;

        .show3 {
            width: 100%;
            height: 352px;
            background: rgba(0, 0, 0, 1);
            opacity: 0.7;
            position: absolute;
            left: 0;
            top: 298px;
            padding: 0 46px 0 35px;
            box-sizing: border-box;
            transition: all 0.5s ease-out;

            .h2 {
                // width: 200px;
                height: 18px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                position: absolute;
                top: 16px;
                text-align: center;
                transition: all 0.5s ease-out;
            }

            p {
                width: 183px;
                height: 64px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 227, 94, 1);
                margin-top: 162px;
                line-height: 28px;
            }
        }
    }

    .item05 {
        position: relative;
        overflow: hidden;

        .show4 {
            width: 100%;
            height: 352px;
            background: rgba(0, 0, 0, 1);
            opacity: 0.7;
            position: absolute;
            left: 0;
            top: 298px;
            padding: 0 46px 0 35px;
            box-sizing: border-box;
            transition: all 0.5s ease-out;

            .h2 {
                // width: 200px;
                height: 18px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                position: absolute;
                top: 16px;
                text-align: center;
                transition: all 0.5s ease-out;
            }

            p {
                width: 183px;
                height: 64px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 227, 94, 1);
                margin-top: 162px;
                line-height: 28px;
            }
        }
    }

    @include md {
        .Exhibitionactivities {
            width: 9rem;
        }
    }

    @include sm {
        .Exhibitionactivities {
            width: 9rem;
        }

        .list_warp {
            .list_item {
                margin-bottom: 12px;
                width: 100%;

                .item .show {
                    top: 0;
                    margin-top: 0;
                    height: 100%;
                }

                img {
                    filter: blur(10px);
                }

                .item .show .h2 {
                    top: px2rem(30) !important;
                    color: rgba(250, 210, 20, 1);
                }

                .learn-more {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0, 0);

                    .arrow {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
</style>
