var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Exhibition_Warp", style: _vm.bgimgs }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "contents" }, [
        _c("div", { staticClass: "titles" }, [
          _c("h3", [_vm._v(_vm._s(_vm.$t("ExhibitionIntroduction.Titke")))])
        ]),
        _c("div", { staticClass: "Video_introduction" }, [
          _c("div", { staticClass: "left" }, [
            _vm.$i18n.locale === "zh"
              ? _c("video", {
                  staticClass: "vid",
                  attrs: {
                    src: "https://vid.conmart.cn/expo/Conmart-Expo-2020.mp4",
                    controls: "controls",
                    poster: "/images/191115131530.png"
                  }
                })
              : _vm._e(),
            _vm.$i18n.locale === "en"
              ? _c("video", {
                  staticClass: "vid",
                  attrs: {
                    src: "https://vid.conmart.cn/expo/Conmart-Expo-2020.mp4",
                    controls: "controls",
                    poster: "/images/191115131530.png"
                  }
                })
              : _vm._e()
          ]),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "box_s" }, [
              _c("h4", [
                _vm._v(
                  _vm._s(_vm.$t("ExhibitionIntroduction.PromotionalVideo"))
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("ExhibitionIntroduction.PropagandaContent"))
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }