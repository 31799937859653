var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Exhibitionactivities", class: _vm.$route.params.lang },
    [
      _c("div", { staticClass: "title" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("ExhibitionActivities.Title")))])
      ]),
      _c("div", { staticClass: "list_warp" }, [
        _c("ul", { staticClass: "list" }, [
          _c("li", { staticClass: "list_item  list_item0" }, [
            _c(
              "a",
              {
                staticClass: "item01 item",
                attrs: { href: "javaScript:void(0);" }
              },
              [
                _c("img", { attrs: { src: "/images/18E12A67.jpg", alt: "" } }),
                _c("div", { staticClass: "show" }, [
                  _c("h2", { staticClass: "h2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("ExhibitionActivities.ActivityList.Title0"))
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.ListItem0")
                      )
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "learn-more" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "/" +
                      _vm.$route.params.lang +
                      "/conferenceactivities#MainActivity"
                  }
                },
                [
                  _c("div", { staticClass: "arrow" }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.LearnMore")
                      )
                    )
                  ])
                ]
              )
            ])
          ]),
          _c("li", { staticClass: "list_item list_item1" }, [
            _c(
              "a",
              { staticClass: "item02 item", attrs: { href: "javaScript:;" } },
              [
                _c("img", { attrs: { src: "/images/1DA263B7.jpg", alt: "" } }),
                _c("div", { staticClass: "show" }, [
                  _c("h2", { staticClass: "h2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("ExhibitionActivities.ActivityList.Title1"))
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.ListItem1")
                      )
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "learn-more" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "/" +
                      _vm.$route.params.lang +
                      "/conferenceactivities#MainActivity"
                  }
                },
                [
                  _c("div", { staticClass: "arrow" }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.LearnMore")
                      )
                    )
                  ])
                ]
              )
            ])
          ]),
          _c("li", { staticClass: "list_item list_item2" }, [
            _c(
              "a",
              { staticClass: "item03 item", attrs: { href: "javaScript:;" } },
              [
                _c("img", { attrs: { src: "/images/8182285F.jpg", alt: "" } }),
                _c("div", { staticClass: "show" }, [
                  _c("h2", { staticClass: "h2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("ExhibitionActivities.ActivityList.Title2"))
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.ListItem2")
                      )
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "learn-more" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "/" +
                      _vm.$route.params.lang +
                      "/conferenceactivities#MainActivity"
                  }
                },
                [
                  _c("div", { staticClass: "arrow" }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.LearnMore")
                      )
                    )
                  ])
                ]
              )
            ])
          ]),
          _c("li", { staticClass: "list_item list_item3" }, [
            _c(
              "a",
              { staticClass: "item04 item", attrs: { href: "javaScript:;" } },
              [
                _c("img", { attrs: { src: "/images/52815E65.jpg", alt: "" } }),
                _c("div", { staticClass: "show" }, [
                  _c("h2", { staticClass: "h2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("ExhibitionActivities.ActivityList.Title3"))
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.ListItem3")
                      )
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "learn-more" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "/" +
                      _vm.$route.params.lang +
                      "/conferenceactivities#MainActivity"
                  }
                },
                [
                  _c("div", { staticClass: "arrow" }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.LearnMore")
                      )
                    )
                  ])
                ]
              )
            ])
          ]),
          _c("li", { staticClass: "list_item list_item4" }, [
            _c(
              "a",
              { staticClass: "item05 item", attrs: { href: "javaScript:;" } },
              [
                _c("img", { attrs: { src: "/images/668282A0.jpg", alt: "" } }),
                _c("div", { staticClass: "show" }, [
                  _c("h2", { staticClass: "h2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("ExhibitionActivities.ActivityList.Title4"))
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.ListItem4")
                      )
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "learn-more" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "/" +
                      _vm.$route.params.lang +
                      "/conferenceactivities#MainActivity"
                  }
                },
                [
                  _c("div", { staticClass: "arrow" }),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("ExhibitionActivities.ActivityList.LearnMore")
                      )
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }